

.navigationBar .menuItem {
    font-size: 1rem;
    

    color: white;
    margin: 0% 1% 2% 1%;
    padding: 2% 1% 2% 1%;
    text-align: center;
    margin: 0 auto;
    font-weight: 100;
    
    justify-content: center;
}

.navigationBar .menuItem:hover {
    background-color: rgba(66,129,143,255);
    border-bottom: solid white 5px;
    color: white;
    animation-name: fade;
    animation-duration: 1s;
}

.navBarWrapper {
    background-color: rgba(2, 2, 2, 0.4);
    width:  100%;
    height: 6vw;
    
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}
.navigationBar {
    width: 72vw;
    margin: 0 auto;
}
.burgerMenu {
    display: none;
}
    
.footerBackground {
    background-color: rgba(2, 2, 2, 0.4);
    border-top: solid black 1px;
    margin-top: 5%;

}
.menuWrapper {
    width: 100%;
    display: flex;
    margin: 0 auto;
    
}

@keyframes fade {
    0% {
        background-color: rgba(0,0,0,0);
    }
    100% {
        background-color: rgba(66,129,143,255);
    }
}
@media (max-width: 1270px) {

    .navBarWrapper {
        display: none;
    }
    .burgerMenu {
        display: inline;
    }
}