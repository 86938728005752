:root * {
    font-family: 'Kanit', sans-serif;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    color: black;

}

#cling-on-logo {
    width: 93%;
    position: relative;
    display: block;
    margin-left: 7%;

}

.App {
    background-image: url('https://image.jimcdn.com/app/cms/image/transf/none/path/s03fb617b319ccf37/backgroundarea/i43d30a346a0e75d0/version/1603433921/image.jpg%22');
    background-size: cover;
    background-attachment: fixed;
}

small {
    text-align: center;
}

.clearfix {
    overflow: auto;
}

.clear-order {
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 4px;
}

.active {
    background-color: rgba(66, 129, 143, 255);
    border-bottom: solid white 5px;
    color: white;

}

.form {
    width: 50%;
    background-color: #F3F3F3;
    padding: 5%;
    margin: 0 auto;
}

.pictureFormat {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.instagramPosts {
    width: 33.1%;
    padding-left: 0.5%;
    padding-bottom: 0.5%;
    display: inline-block;
    position: relative;
}

.sizeFix {
    max-width: 100%;

}

iframe {
    display: block;
    margin: 0 auto;
}

.productLimitSize {
    max-height: 400px;
    margin-bottom: 3%;

}

.productLimitSize:hover {
    animation: zoom 1s;
    -webkit-animation-fill-mode: forwards;
}

.find-local-retailer-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

h1 {
    font-weight: 100;
}

h3 {
    text-align: center;
}

#tips-and-tricks {
    background-color: rgba(66, 129, 143, 255);
    width: 100%;
    margin-top: 10%;
}

.col-left {
    margin-top: 5%;
    padding-bottom: 5%;
    height: 100%;
    background-color: white;
}

.col-right {
    margin-top: 5%;
}

.order-item {
    text-align: left;
}

.order-item-price {
    text-align: right;
}

#login-container {
    display: block;
    background-color: #FFFFFF;
    margin: 0 auto;
    border-radius: 5px;
    padding: 0 10px 5px 10px;
    margin-top: 8%;
    margin-bottom: 40vh;
}

#login-container h3 {
    text-align: center;
}

hr.style-eight {
    margin-top: 10%;
    overflow: visible;
    /* For IE */
    padding: 0;
    border: none;
    border-top: medium double #333;
    color: #333;
    text-align: center;
}

hr.style-eight:after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: white;
}

.alert {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 2%;
    margin-top: 2%;

}

.email {
    color: black;
    font-size: 1.5rem;
}

.tips-and-tricks p {
    text-align: left;
}

#contact-submit {
    background-color: blue;
    border: none;
    border-radius: 2px;
    color: white;
    padding: 2px 10px 2px 10px;
}

.store-brush-display {
    min-height: 200px;
}

.quote {
    text-align: right;
    font-style: italic;
}

#quote-box {
    width: 70%;
    margin: 0 auto;
}

.brush-in-yellow-bag {
    display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.outOfStockLabel {
    
    margin: auto;
    position: absolute;
    top: 40%;
    left: 10%;
    bottom: 0;
    right: 0;
    color: red;
}


@keyframes zoom {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.05);

    }
}

/* @media (max-width: 768px) {
    .productLimitSize {
        max-height: 300px;
        margin-bottom: 0%;
    }
} */
@media (max-width: 1200px) {
    .productLimitSize {
        max-height: 300px;
        margin-bottom: 3%;
    }
    .outOfStockLabel {
        margin: auto;
        margin-top: 50%;
        position: relative;
        
        left: 10%;
        bottom: 0;
        right: 0;
        color: red;
    }
}

@media (max-width: 1270px) {
    .home-column-picture-fix {
        display: none;
    }

    .brush-in-yellow-bag {
        display: block;
    }
}