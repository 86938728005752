/* Position and size our burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 30px;
  left: 32px;
  top: 32px;
}

/* Color to our burger */
.bm-burger-bars {
  background: black;
}

/* Color to our burger icon on hover */
.bm-burger-bars-hover {
  background: #022144;
}

/* Size of the cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color of cross button */
.bm-cross {
  background: #fff;
}


/* Sidebar wrapper styles */
.bm-menu-wrap {
  position: fixed;
  height: 100vh;
  overflow-x: hidden;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
}

.bm-menu {
  background-color: rgb(151, 150, 150);
  padding: 2.5em 1.5em 0 0;
  height: 100vh;
  overflow: hidden !important;
}

.bm-item-list {
  padding: 0.8em;
}

.menu-item {
  position: relative;
  font-size: 1.5em;
  text-decoration: none;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  padding: 10px;
}

.menu-item:hover {
  background-color: rgba(66,129,143,255);
  border-bottom: solid white 5px;
  color: white;
  animation-name: fade;
  animation-duration: 1s;
}

.active-item {
  font-weight: 500;
}

.menu-item:hover:before,
.active-item:before {
  visibility: visible;
  height: 3px;
}

@keyframes fade {
  0% {
      background-color: rgba(0,0,0,0);
  }
  100% {
      background-color: rgba(66,129,143,255);
  }
}